<template>
  <Layout :tituloPagina="$route.path.substr(0, 9) == '/facturas' ? `Facturas | ${modoFactura == 'nueva' ? 'Nueva' : 'Edición'}` : `Ventas | Factura rápida`">
    <!-- Inicio de factura -->
    <div v-show="facturaVisible">
      <div class="card">
        <div class="card-header justify-content-between d-flex align-items-center">
          <h4 class="card-title">
            {{ modoFactura == 'nueva' ? 'Nueva' : 'Edición de' }} factura
          </h4>
          <div>
            <div class="dropdown">
              <a
                class="dropdown-toggle"
                href="javascript: void(0);"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <eva-icon
                  name="more-horizontal-outline"
                  data-eva-width="20"
                  data-eva-height="20"
                  :class="{
                    'fill-dark': $store.state.layout.layoutMode == 'light',
                    'fill-white': $store.state.layout.layoutMode == 'dark'
                  }"
                ></eva-icon>
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    @click="modoFactura == 'nueva' ? guardar() : actualizar()"
                    onclick="return false;"
                    v-show="!animproc"
                  >
                    <i class="mdi mdi-content-save text-success"></i>
                    {{ modoFactura == 'nueva' ? 'Guardar' : 'Actualizar' }}
                  </a>
                  <span class="dropdown-item disabled" v-if="animproc">
                    <i class="mdi mdi-concourse-ci mdi-spin"></i>
                    {{ modoFactura == 'nueva' ? 'Guardar' : 'Actualizar' }}
                  </span>
                </li>
                <li>
                  <a
                    class="dropdown-item" href="#"
                    onclick="return false;" @click="cerrar()"
                  >
                    <i class="mdi mdi-close text-dark"></i>
                    Cerrar
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-2">
              <label>Serie</label>
              <template v-if="factura.ns == undefined">
                <select
                  name="id_serie"
                  class="form-select"
                  v-model="factura.id_serie"
                >
                  <option
                    v-for="serie in series"
                    :key="serie.id"
                    :value="serie.id"
                  >
                    {{ serie.prefijo }}
                  </option>
                </select>
              </template>

              <template v-if="factura.ns != undefined">
                <input
                  type="text"
                  v-model="factura.ns"
                  placeholder="A0000"
                  class="form-control"
                  readonly
                />
              </template>
            </div>
            <div class="col-sm-3">
              <label>Fecha de registro</label>
              <input
                class="form-control text-right"
                readonly
                placeholder="DD/MM/AAAA"
                autocomplete="off"
                v-model="factura.fecha"
              />
            </div>
            <div class="col-sm-3" v-if="factura.pospago">
              <label>Fecha para pago: </label>&nbsp;
              <div class="form-check form-switch" style="display: inline-block;">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="factura_palancaFechaParaPago"
                  :checked="fechaParaPagoActiva"
                  @change="palancaFechaParaPago()"
                />
                <label
                  class="form-check-label"
                  for="factura_palancaFechaParaPago"
                ></label>
              </div>

              <input
                type="date"
                name="fechaParaPago"
                v-model="factura.fecha_para_pago"
                class="form-control"
                placeholder="DD/MM/AAAA"
                autocomplete="off"
                v-show="fechaParaPagoActiva"
              />
            </div>
            <div class="col-sm-3" v-if="factura.pospago">
              <label>Fecha de vencimiento:</label>&nbsp;
              <div class="form-check form-switch" style="display: inline-block;">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="factura_palancaFechaVencimiento"
                  :checked="fechaVencimientoActiva"
                  @change="palancaFechaVencimiento()"
                />
                <label
                  class="form-check-label"
                  for="factura_palancaFechaVencimiento"
                ></label>
              </div>

              <input
                type="date"
                name="fechaVencimiento"
                v-model="factura.fecha_vencimiento"
                class="form-control"
                placeholder="DD/MM/AAAA"
                autocomplete="off"
                v-show="fechaVencimientoActiva"
              />
            </div>
          </div>

          <br />

          <div class="row">
            <div class="col-md-5">
              <label>Cliente</label>
              <div class="input-group">
                <div
                  class="input-group-text bg-white"
                  style="min-width: 60px"
                  v-if="factura.numero_cliente != null"
                >
                  {{ factura.numero_cliente }}
                </div>
                <input
                  name="nombre"
                  class="form-control"
                  placeholder="Seleccionar cliente"
                  v-model="factura.nombre_cliente"
                  readonly
                />
                <button
                  type="button"
                  class="btnSeleccionCliente btn btn-success"
                  @click="mostrarSeleccionCliente()"
                  v-if="factura.pospago && modoFactura == 'nueva'"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="col-md-7">
              <label>Nota</label>
              <textarea
                name="nota"
                class="form-control"
                placeholder="Descripción de la factura"
                rows="5"
                v-model="factura.nota"
              ></textarea>
              <br />
              <div class="row">
                <div class="col-md-4">
                  <h5>Contrato asignado</h5>
                  <div v-show="modoFactura == 'nueva'">
                    <div class="input-group">
                      <select
                        class="form-select"
                        v-model="factura.id_contrato"
                      >
                        <option value="0">Ninguno</option>
                        <option
                          v-for="id in idsContratos"
                          :key="id"
                          :value="id"
                        >
                          {{ id }}
                        </option>
                      </select>
                      <button class="btn btn-info" @click="infoContrato(factura.id_contrato)" title="Información del contrato">
                        <i class="mdi mdi-information-outline"></i>
                      </button>
                    </div>
                  </div>

                  <div
                    class="input-group"
                    v-show="
                      (factura.id_contrato != 0 ||
                      factura.id_contrato != null) &&
                      modoFactura == 'edicion'
                    "
                  >
                    <input
                      v-model="factura.id_contrato"
                      class="form-control text-right"
                      readonly
                    />
                    <router-link
                      :to="{
                        name: 'edicionContrato',
                        params: {
                          id_cliente: factura.id_cliente,
                          id_contrato: factura.id_contrato
                        }
                      }"
                      class="btn btn-warning"
                      v-if="
                        factura.id_cliente != null &&
                        factura.id_contrato != null
                      "
                    >
                      <i class="mdi mdi-eye"></i>
                    </router-link>
                    <button class="btn btn-info" @click="infoContrato(factura.id_contrato)" title="Información del contrato">
                      <i class="mdi mdi-information-outline"></i>
                    </button>
                  </div>
                </div>

                <div
                  class="col-md-8"
                  v-show="
                    factura.id_contrato != 0 && factura.id_contrato != null
                  "
                >
                  <h5>Periodo asignado</h5>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="input-group">
                        <span class="input-group-text">
                          Inicio
                        </span>
                        <input
                          type="date"
                          class="form-control"
                          name="inicioPeriodo"
                          placeholder="DD/MM/AAAA"
                          v-model="factura.inicio_periodo"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-group">
                        <span class="input-group-text">
                          Fin
                        </span>
                        <input
                          type="date"
                          class="form-control"
                          name="finPeriodo"
                          placeholder="DD/MM/AAAA"
                          v-model="factura.fin_periodo"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <!-- Partidas de la factura -->
          <div class="text-right">
            <button
              type="button"
              class="btn btn-outline-success btn-sm"
              @click="mostrarNuevaPartida()"
            >
              <i class="mdi mdi-plus-thick"></i> partida
            </button>
          </div>
          <div class="conTblPartidas table-responsive">
            <table class="tblPartidas table table-hover table-striped align-middle table-nowrap">
              <thead>
                <tr>
                  <th style="width: 50px">#</th>
                  <th style="min-width: 200px">Producto</th>
                  <th style="width: 120px">P/U</th>
                  <th style="width: 100px">Cantidad</th>
                  <th style="width: 120px">S. Total</th>
                  <th style="width: 120px">+Carg.</th>
                  <th style="width: 120px">-Desc.</th>
                  <th style="width: 120px">Total</th>
                  <th style="width: 180px">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-right"
                  v-for="(partida, index) in factura.partidas"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td class="text-left">{{ partida.nombre }}</td>
                  <td>{{ hrr.fmon(partida.precio, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</td>
                  <td>{{ partida.cantidad }}</td>
                  <td>{{ hrr.fmon(partida.sub_total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</td>
                  <td>{{ hrr.fmon(partida.valor_total_cargos, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</td>
                  <td>{{ hrr.fmon(partida.valor_total_descuentos, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</td>
                  <td>{{ hrr.fmon(partida.total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-warning btn-sm"
                      @click="editarPartida(index)"
                    >
                      <i class="mdi mdi-square-edit-outline font-size-13"></i>
                      Editar
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="eliminarPartida(index)"
                    >
                      <i class="mdi mdi-trash-can-outline font-size-13"></i>
                      Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <br />

          <div class="row">
            <!-- CARGOS -->
            <div class="col-md-6">
              <div class="row">
                <div class="col-xs-9">
                  <label>+ Cargos globales</label>&nbsp;
                  <div class="form-check form-switch" style="display: inline-block;">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="factura_palancaCargos"
                      :checked="cargosActivos"
                      @change="palancaCargos()"
                    />
                    <label
                      class="form-check-label"
                      for="factura_palancaCargos"
                    ></label>
                  </div>
                </div>

                <div class="col-xs-3 text-right">
                  <button
                    type="button"
                    class="btn btn-outline-success btn-sm"
                    v-if="cargosActivos == true"
                    @click="agregarCargo()"
                  >
                    <i class="mdi mdi-plus-thick"></i>
                    Nuevo
                  </button>
                </div>
              </div>

              <div class="conTblCargos table-responsive" v-show="cargosActivos">
                <table class="tblCargos table table-hover table-striped align-middle">
                  <thead>
                    <tr>
                      <th>Concepto</th>
                      <th style="width: 100px">%</th>
                      <th style="width: 100px">{{ monedaSistema.simbolo }}</th>
                      <th style="width: 60px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(cargo, index) in factura.cargos" :key="index">
                      <td>
                        <input
                          type="text"
                          name="concepto"
                          placeholder="Concepto del cargo global"
                          class="form-control"
                          v-model="cargo.concepto"
                        />
                      </td>
                      <td>
                        <input
                          name="porcentaje"
                          class="form-control text-right"
                          placeholder="0"
                          v-model="cargo.porcentaje"
                          @change="actualizarValorCargo(index)"
                        />
                      </td>
                      <td>
                        <input
                          name="valor"
                          class="form-control text-right"
                          placeholder="0"
                          v-model="cargo.valor"
                          @change="
                            cargo.valor = parseFloat(cargo.valor).toFixed(3),
                            actualizarPorcentajeCargo(index)
                          "
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-danger"
                          @click="eliminarCargo(index)"
                        >
                          <i class="mdi mdi-close-thick"></i>
                        </button>
                        <input name="id" v-model="cargo.id" hidden />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- DESCUENTOS -->
            <div class="col-md-6">
              <div class="row">
                <div class="col-xs-9">
                  <label>- Descuentos globales</label>&nbsp;
                  <div class="form-check form-switch" style="display: inline-block;">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="factura_palancaDescuentos"
                      :checked="descuentosActivos"
                      @change="palancaDescuentos()"
                    />
                    <label
                      class="form-check-label"
                      for="factura_palancaDescuentos"
                    ></label>
                  </div>
                </div>
                <div class="col-xs-3 text-right">
                  <button
                    type="button"
                    class="btn btn-outline-success btn-sm"
                    v-show="descuentosActivos"
                    @click="agregarDescuento()"
                  >
                    <i class="mdi mdi-plus-thick"></i>
                    Nuevo
                  </button>
                </div>
              </div>

              <div class="conTblDescuentos table-responsive" v-show="descuentosActivos">
                <table class="tblDescuentos table table-hover table-striped align-middle">
                  <thead>
                    <tr>
                      <th>Concepto</th>
                      <th style="width: 100px">%</th>
                      <th style="width: 100px">{{ monedaSistema.simbolo }}</th>
                      <th style="width: 60px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(descuento, index) in factura.descuentos"
                      :key="index"
                    >
                      <td>
                        <input
                          type="text"
                          name="concepto"
                          placeholder="Concepto del descuento global"
                          class="form-control"
                          v-model="descuento.concepto"
                        />
                      </td>
                      <td>
                        <input
                          name="porcentaje"
                          class="form-control text-right"
                          placeholder="0"
                          v-model="descuento.porcentaje"
                          @change="actualizarValorDescuento(index)"
                        />
                      </td>
                      <td>
                        <input
                          name="valor"
                          class="form-control text-right"
                          placeholder="0"
                          v-model="descuento.valor"
                          @change="
                            descuento.valor = parseFloat(descuento.valor).toFixed(3),
                            actualizarPorcentajeDescuento(index)
                          "
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-danger"
                          @click="eliminarDescuento(index)"
                        >
                          <i class="mdi mdi-close-thick"></i>
                        </button>
                        <input name="id" v-model="descuento.id" hidden />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <br />

          <div class="row">
            <div class="offset-md-8 col-md-4 text-right">
              <div class="row">
                <label class="col-sm-6 col-6 text-start">Sub total</label>
                <div class="col-sm-6 col-6">
                  {{ hrr.fmon(factura.sub_total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                </div>
              </div>
              <div class="row">
                <label class="col-sm-6 col-6 text-start">+Cargos</label>
                <div class="col-sm-6 col-6">
                  {{ hrr.fmon(factura.valor_total_cargos, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                </div>
              </div>
              <div class="row">
                <label class="col-sm-6 col-6 text-start">-Descuentos</label>
                <div class="col-sm-6 col-6">
                  {{ hrr.fmon(factura.valor_total_descuentos, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                </div>
              </div>
              <div class="row" v-show="piva > 0">
                <label class="col-sm-6 col-6 text-start">%{{ piva }} IVA</label>
                <div class="col-sm-6 col-6">
                  {{ hrr.fmon(factura.iva, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                </div>
              </div>
              <div class="row">
                <label class="col-sm-6 col-6 text-start">Total</label>
                <div class="col-sm-6 col-6">
                  {{ hrr.fmon(factura.total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <router-link
            :to="{
              name: 'abonosFactura',
              params: { id_factura: factura.id }
            }"
            v-if="factura.saldo < factura.total"
            class="btn btn-secondary"
          >
            <i class="mdi mdi-format-list-bulleted"></i>
            Abonos aplicados
          </router-link>
        </div>
        <div class="col-md-6 text-right">
          <div class="btn-group">
            <button type="button" class="btn btn-secondary" @click="cancelar()">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="modoFactura == 'nueva' ? guardar() : actualizar()"
              v-if="factura.pospago"
              :disabled="animproc"
            >
              <i class="mdi mdi-content-save" v-show="!animproc"></i>
              <i class="mdi mdi-concourse-ci mdi-spin" v-show="animproc"></i>
              <span v-show="modoFactura == 'nueva'">
                {{ animproc ? ' Guardando' : ' Guardar' }}
              </span>
              <span v-show="modoFactura == 'edicion'">
                {{ animproc ? ' Actualizando' : ' Actualizar' }}
              </span>
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="generar()"
              v-if="factura.pospago == false"
              :disabled="animproc"
            >
              <i class="mdi mdi-cog-outline" v-show="!animproc"></i>
              <i class="mdi mdi-concourse-ci mdi-spin" v-show="animproc"></i>
              {{ animproc ? 'Generando' : 'Generar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
    
    <!-- Fin de factura -->

    <cmp-seleccion-cliente
      v-if="seleccionClienteVisible"
      v-on:cancelar="cancelarSeleccionCliente()"
      v-on:cliente-seleccionado="asignarCliente($event)"
    ></cmp-seleccion-cliente>

    <cmp-partida-factura
      v-if="partidaFacturaVisible"
      :modo="modoPartida"
      :partida-editar="partidaEditar"
      v-on:cerrar="cerrarPartidaFactura()"
      v-on:registrar="registrarNuevaPartida($event)"
      v-on:actualizar="actualizarPartida($event)"
      v-on:devolver="devolverPartida()"
    ></cmp-partida-factura>
    
    <mdl-info-contrato ref="mdlInfoCrt"></mdl-info-contrato>
  </Layout>
</template>

<script src="./EdicionFactura.js"></script>

<style scoped>
</style>